<template>
  <document-control
    source="documents/getPaymentErrors"
    pin-name="payment-errors"
  >
    <template #action>
      <payment-errors-action />
    </template>
  </document-control>
</template>
<script>
import DocumentControl from '@/components/shared/document-control.vue'
import PaymentErrorsAction from './payment-errors-action.vue'

export default {
  components:{
    DocumentControl ,
    PaymentErrorsAction
  }
}
</script>
