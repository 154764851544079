<template>
  <v-btn
    color="#5cb7b1"
    dark
    tabindex="-1"
    @click="validateAndRecalculatePayments"
  >
    Рассчитать
  </v-btn>
</template>
<script>
import {validateAndRecalculatePayments} from './payments'
export default {
  methods:{
    validateAndRecalculatePayments
  }
}
</script>